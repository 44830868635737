exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-arcade-js": () => import("./../../../src/pages/arcade.js" /* webpackChunkName: "component---src-pages-arcade-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-merch-js": () => import("./../../../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-schematics-js": () => import("./../../../src/pages/schematics.js" /* webpackChunkName: "component---src-pages-schematics-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */)
}

